import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import NavInn from "../components/inner/navInn"
import SEO from "../components/seo"
import Hero from "../components/common/hero"
import Btn from "../components/common/button"

import ExploreForm from "../components/forms/exploreForm"

// Importing Contentful Components
import ExploreHeader from "./exploreHeader"



const ExplorePage = () => (
  <Layout>
    <SEO title="Careers - Explore Privately" />
    <Hero className="hero--inn">
      <div className="cont--spaced">
        <div className="hero__head">
          <div className="hero__logo__cont">
            <a href="/" className="hero__logo">PrimeSet</a>
          </div>
          <NavInn className="" />
          {/* <Btn btnTitle="Say Hello!" className="btn--prim" Link btnLink="/contact" /> */}
        </div>
        <div className="hero__content">
          <ExploreHeader />
          <div className="hero__content--sep"></div>
        </div>
      </div>
    </Hero>

    <section className="sect__cont sect--explore">
      <div className="cont--spaced">
        <ExploreForm />
      </div>
    </section>
  </Layout>
)

export default ExplorePage
