import { Link } from "gatsby"
import React from "react"


// Importing custom inputs
import { FormRowSingle,
         FormRowDouble,
         FormInnTitle,
         FormInnSect,
         InputCheck,
         InputRadio,
         InputSingle } from "../common/forms/inputParts"

// Form Sections:
// Section #1: Personal Information
const FormSectPersonalInfo = () => (
  <FormInnSect sectAnchor="PersonaInformation">
    <FormInnTitle titleAnchor="PersonaInformation" titleText="Personal Information" titleAnchorOrder="1" />
    <FormRowDouble>
      <InputSingle Holder="First Name" className="input--lg" InputName="firstname" />
      <InputSingle Holder="Last Name" className="input--lg" InputName="lastname" />
    </FormRowDouble>
    <FormRowSingle>
      <InputSingle Holder="Email Address" className="input--lg" InputName="email" />
    </FormRowSingle>
    <FormRowSingle>
      <InputSingle Holder="How did you hear about PrimeSet?" className="input--lg" InputName="hearaboutus" />
    </FormRowSingle>
    <FormRowDouble className="form--optional">
      <InputSingle Holder="Add Twitter" className="input--lg input--il" InputName="twitter">
        <span className="i i-twitt"></span>
      </InputSingle>
      <InputSingle Holder="Add LinkedIn" className="input--lg input--il" InputName="linkedin">
        <span className="i i-linkd"></span>
      </InputSingle>
    </FormRowDouble>
  </FormInnSect>
)


// Section #2: Areas of Expertise
const FormSectExpertise = () => (
  <FormInnSect sectAnchor="AreasOfExpertise">
    <FormInnTitle titleAnchor="AreasOfExpertise" titleText="Choose areas of expertise" titleAnchorOrder="2" />

    <FormRowSingle className="form__row--inline">
      <InputCheck checkName="expert1" checkId="ex1" checkLabel="Engineering" />
      <InputCheck checkName="expert2" checkId="ex2" checkLabel="UX/UI" />
      <InputCheck checkName="expert3" checkId="ex3" checkLabel="Data Science" />
      <InputCheck checkName="expert4" checkId="ex4" checkLabel="Product Design" />
    </FormRowSingle>
  </FormInnSect>
)

// Section #3: Earliest you can move
const FormSectCanMove = () => (
  <FormInnSect sectAnchor="CanMoveWhen">
    <FormInnTitle titleAnchor="CanMoveWhen" titleText="Earliest You Can Move" titleAnchorOrder="3" />
    
    <FormRowSingle className="form__row--inline">
      <InputRadio radioName="radio-move" radioId="mo1" radioLabel="Next Month" />
      <InputRadio radioName="radio-move" radioId="mo2" radioLabel="1-3 month" />
      <InputRadio radioName="radio-move" radioId="mo3" radioLabel="4-6 month" />
      <InputRadio radioName="radio-move" radioId="mo4" radioLabel=">6 Month" />
    </FormRowSingle>
  </FormInnSect>
)


// Section #4: Stages
const FormSectStages = () => (
  <FormInnSect sectAnchor="Stages">
    <FormInnTitle titleAnchor="Stages" titleText="Choose a stage" titleAnchorOrder="4" />

    <FormRowSingle className="form__row--list">
      <InputRadio radioName="radio-seed" radioId="se1" radioLabel="Seed ( 1-10 )" />
      <InputRadio radioName="radio-seed" radioId="se2" radioLabel="Series A ( 10-30 )" />
      <InputRadio radioName="radio-seed" radioId="se3" radioLabel="Series B ( 30-100 )" />
      <InputRadio radioName="radio-seed" radioId="se4" radioLabel="Series C or Later ( >100 )" />
    </FormRowSingle>
    
  </FormInnSect>
)


// Section #5: Roles
const FormSectRoles = () => (
  <FormInnSect sectAnchor="Roles">
    <FormInnTitle titleAnchor="Roles" titleText="Choose suitable Roles" titleAnchorOrder="5" />
    <FormRowSingle className="form__row--list">
      <InputCheck checkName="rol1" checkId="rol1" checkLabel="Managment" />
      <InputCheck checkName="rol2" checkId="rol2" checkLabel="Architect/Senior IC" />
      <InputCheck checkName="rol3" checkId="rol3" checkLabel="Co-founder" />
      <InputCheck checkName="rol4" checkId="rol4" checkLabel="Founding Team" />
      <InputCheck checkName="rol5" checkId="rol5" checkLabel="Consultant/Advisor" />
    </FormRowSingle>
  </FormInnSect>
)


const FormSectTellMore = () => (
  <FormInnSect sectAnchor="TellMore">
    <FormInnTitle titleAnchor="TellMore" titleText="Tell Us More About Yourself" titleAnchorOrder="6" />

    <div className="input--area">
      <textarea name="message" placeholder="Example: type of company you are/not interested in, blog URL, etc..." />
    </div>
  </FormInnSect>
)

const FormSectFoot = () => (
  <FormInnSect>
    <button className="btn btn--lg btn--prim fw" type="submit" data-hover="Submit Information">Submit Information</button>
  </FormInnSect>
)

const ExploreForm = () => (
  <form className="form__cont form--explore" name="explore" method="POST" data-netlify="true">
    <input type="hidden" name="form-name" value="explore" />

    <div className="form__inn">
      <FormSectPersonalInfo />
      <FormSectExpertise />
      <FormSectCanMove />

      <FormRowDouble>
        <FormSectStages />
        <FormSectRoles />
      </FormRowDouble>

      <FormSectTellMore />
      <FormSectFoot />
    </div>
  </form>
)



export default ExploreForm
